import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import headerStyles from "./header.module.css"

export default function Header() {
  return(
    <div className={headerStyles.header}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Paulette Brooks</title>
      </Helmet>
      <h1>
        <Link to="/">Paulette Brooks</Link>
      </h1>
      <div className={headerStyles.menu}>
        <Link to="/cognitive-hypnotherapy/" activeClassName="current">Cognitive Hypnotherapy</Link>,
        <Link to="/work/" activeClassName="current">My Work</Link>,
        <Link to="/about/" activeClassName="current">About</Link>,
        <Link to="/testimonials/" activeClassName="current">Testimonials</Link>
      </div>
    </div>
  )
}
